<script setup lang="ts">
import rootPkgJson from '../../../package.json'

const year = new Date().getFullYear()
const isDevVersion = rootPkgJson.version.toLowerCase().includes('rc')
</script>

<template>
  <footer
    class="bg-muted-200 dark:bg-muted-900 flex h-8 w-full flex-wrap items-center justify-center gap-5 overflow-hidden border-t border-gray-700 px-4 text-sm"
  >
    <div class="flex items-center justify-center gap-2">
      <span>
        {{ year }}
      </span>
      <span>
        <span> ISV demo v{{ rootPkgJson.version }}</span>
      </span>
      <span v-if="isDevVersion" class="text-sky-500"> (Vývojová verze) </span>
    </div>
    <div class="col-span-2 flex justify-end gap-4"></div>
  </footer>
</template>
