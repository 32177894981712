<script setup lang="ts">
import { useSidebar } from '../composables/sidebar'

withDefaults(
  defineProps<{
    subsidebar?: boolean
    expanded?: boolean
  }>(),
  {
    subsidebar: true,
    expanded: false
  }
)

const route = useRoute()
const { isOpen, current, sidebars } = useSidebar()

const isHover = ref(false)

const startSidebars = computed(() =>
  sidebars.value?.filter((sidebar) => !sidebar.position || sidebar.position === 'start')
)
const endSidebars = computed(() => sidebars.value?.filter((sidebar) => sidebar.position === 'end'))

const sidebarCallObj = computed(() => ({
  '-translate-x-full xl:translate-x-0': isOpen
}))
</script>

<template>
  <div class="fixed start-0 top-0 z-50 flex h-full w-20" @mouseenter="isHover = true" @mouseleave="isHover = false">
    <!-- Icon sidebar -->
    <div
      class="border-muted-200 dark:border-muted-700 dark:bg-muted-800 bg-muted-100 pointer-events-auto relative z-50 flex h-full flex-col border-r transition-all duration-300"
      :class="sidebarCallObj"
      style="min-width: 5rem"
    >
      <slot></slot>

      <!-- Top Menu -->
      <div>
        <slot name="top">
          <div class="text-muted-800 dark:text-muted-100 text-center" v-text="'DEMO'" />
        </slot>

        <div class="flex h-12 w-full items-center justify-center">
          <nuxt-link
            class="text-muted-800 dark:text-muted-100 flex size-8 items-center justify-center rounded-2xl text-2xl transition-colors duration-300"
            data-tooltip="Domů"
            data-tooltip-position="right"
            :disabled="route.name === 'index'"
            to="/"
          >
            <NuxtIcon name="mdi-home" />
          </nuxt-link>
        </div>

        <hr class="border-1 border-muted-500 rounded" />

        <TairoSidebarNavigationItem v-for="item in startSidebars" :key="item.title" :sidebar="item" />
      </div>
      <div class="mt-auto">
        <TairoSidebarNavigationItem v-for="item in endSidebars" :key="item.title" :sidebar="item" />
        <slot name="end"></slot>
      </div>
    </div>

    <!-- Menu panel -->
    <div
      v-if="current?.subsidebar?.component"
      class="border-muted-200 dark:border-muted-700 dark:bg-muted-800 bg-muted-100 pointer-events-auto relative z-40 h-full w-56 border-r transition-all duration-300"
      :class="isOpen || isHover ? '' : 'rtl:translate-x-[calc(100%_+_5rem)] translate-x-[calc(-100%_-_5rem)]'"
      style="min-width: 14rem"
    >
      <slot name="subnav">
        <KeepAlive>
          <div class="relative">
            <TairoSidebarBurger class="absolute right-2 top-2" />
            <component :is="current?.subsidebar?.component" :key="current?.subsidebar?.component" />
          </div>
        </KeepAlive>
      </slot>
    </div>
  </div>
</template>
