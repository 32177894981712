<script setup lang="ts">
import { useSidebar } from '../composables/sidebar'
import type { SidebarNavigationItemConfig } from '../types'
import { useAuthStore } from '~/store/auth'

const props = defineProps<{
  sidebar: SidebarNavigationItemConfig
}>()

const authStore = useAuthStore()

const { currentName, isOpen } = useSidebar()

function onSidebarItemClick() {
  if (typeof props.sidebar.click === 'function') {
    return props.sidebar.click()
  }

  currentName.value = props.sidebar.title
  // isOpen.value = true
}

const isAvailable = computed(() => {
  if (props.sidebar.roles?.length) {
    return props.sidebar.roles!.includes(authStore.role)
  }

  return true
})
</script>

<template>
  <div v-if="isAvailable" class="flex h-12 w-full items-center justify-center">
    <span>{{ sidebar.order }}</span>
    <component :is="sidebar.component" v-if="sidebar.component" v-bind="sidebar.props" />
    <NuxtLink
      v-else-if="sidebar.to && sidebar.icon"
      :to="sidebar.to"
      class="text-muted-800 dark:text-muted-100 flex size-12 items-center justify-center rounded-2xl transition-colors duration-300"
      :title="sidebar.title"
    >
      <NuxtIcon v-bind="sidebar.icon" class="text-2xl" />
    </NuxtLink>

    <button
      v-else-if="sidebar.icon"
      type="button"
      class="flex size-12 items-center justify-center rounded-2xl transition-colors duration-300"
      :class="
        currentName === sidebar.title
          ? 'bg-primary-100 text-primary-500 dark:bg-primary-500/10'
          : 'text-muted-800 dark:text-muted-100'
      "
      :title="sidebar.title"
      @click="onSidebarItemClick"
    >
      <NuxtIcon v-bind="sidebar.icon" class="text-2xl" />
    </button>
  </div>
</template>
