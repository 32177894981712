<script setup lang="ts">
const app = useAppConfig()
</script>

<template>
  <div class="flex w-full items-center gap-2">
    <!--    <component-->
    <!--      :is="resolveComponentOrNative(app.tairo.sidebar.toolbar.tools.interval.component)"-->
    <!--      v-if="app.tairo.sidebar.toolbar.tools.interval"-->
    <!--      :key="app.tairo.sidebar.toolbar.tools.interval.component"-->
    <!--      v-bind="app.tairo.sidebar.toolbar.tools.interval.props"-->
    <!--    />-->
    <!--    <div class="ms-auto" />-->
    <template v-for="tool of app.tairo.sidebar.toolbar.tools.left">
      <component
        :is="resolveComponentOrNative(tool.component)"
        v-if="tool.component"
        :key="tool.component"
        v-bind="tool.props"
      />
    </template>
    <div class="ms-auto" />
    <template v-for="tool of app.tairo.sidebar.toolbar.tools.right">
      <component
        :is="resolveComponentOrNative(tool.component)"
        v-if="tool.component"
        :key="tool.component"
        v-bind="tool.props"
      />
    </template>
  </div>
</template>
