<script setup lang="ts">
import { useSidebar } from '../composables/sidebar'

const props = withDefaults(
  defineProps<{
    sidebar?: boolean
    horizontalScroll?: boolean
  }>(),
  {
    sidebar: true
  }
)

const app = useAppConfig()
const { hasSubsidebar } = useSidebar()

const route = useRoute()

const showNavBurger = computed(() => {
  return props.sidebar && app.tairo.sidebar?.toolbar?.showNavBurger && hasSubsidebar.value
})
</script>

<template>
  <div
    class="relative z-10 mb-4 flex h-16 items-center gap-2 border-b border-gray-700 px-4"
    :class="props.horizontalScroll && 'pe-4 xl:pe-10'"
  >
    <BaseHeading
      v-if="app.tairo.sidebar?.toolbar?.showTitle"
      as="h1"
      size="2xl"
      weight="light"
      class="text-muted-800 hidden w-96 md:block dark:text-white"
    >
      <slot name="title">
        <span class="select-none" @dblclick.prevent="startToSimulateAov">
          {{ route.meta.title }}
        </span>
      </slot>
    </BaseHeading>

    <TairoSidebarTools class="h-16" />
  </div>
</template>
